import { type Message, VideoState } from "../../party/interfaces";
import { useContext, useRef, useState } from "react"
import { UserContext } from "../client"
import ReactPlayer from 'react-player'
import usePartySocket from "partysocket/react"

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Room() {
    const context = useContext(UserContext);
    const [users, setUsers] = useState(['']);
    const [state, setState] = useState(VideoState.PAUSE);
    const [url, setURL] = useState("");
    const [video, setVideo] = useState("");

    const playerRef = useRef(null);

    const socket = usePartySocket({
        room: context.room,
        onMessage(event) {
            const data = JSON.parse(event.data) as Message;

            if (data.type == "users-updated") {
                
                if (context.users.length > data.data.users.length) {
                    toast.error('A user has left', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        theme: "colored",
                        transition: Bounce,
                    });
                } else {
                    toast.success('A user has joined', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        theme: "colored",
                        transition: Bounce,
                    });
                }

                context.users = data.data.users;
                setState(data.data.state);
                setUsers(context.users);
            }

            if (data.type == "video-play") {
                setState(VideoState.PLAY);
                const p = playerRef.current.getInternalPlayer();
                p.playVideo()
            }

            if (data.type == "video-pause") {
                setState(VideoState.PAUSE);
                const p = playerRef.current.getInternalPlayer();
                p.pauseVideo()
            }

            if (data.type == "current-state") {
                setVideo(data.data.url);
            }

            if (data.type == "watch-together") {
                setVideo(data.data);
            }

            if (data.type == "video-sync") {
                const p = playerRef.current;
                const time = data.data;
                p.seekTo(time, "seconds");
            }
        }
    });

    function Pause() {
        const message: Message = {
            type: "video-pause",
            data: null
        };
        socket.send(JSON.stringify(message));
    }

    function Play() {
        const p = playerRef.current;
        const time = p.getCurrentTime();

        const message: Message = {
            type: "video-play",
            data: time
        };
        socket.send(JSON.stringify(message));
    }

    function Sync() {
        const p = playerRef.current;
        const time = p.getCurrentTime();
        const message: Message = {
            type: "video-sync",
            data: time
        };
        socket.send(JSON.stringify(message));
    }

    function WatchTogether() {
        const message: Message = {
            type: "watch-together",
            data: url
        };
        socket.send(JSON.stringify(message));
    }

    return (
        <div>
            <h4>Room: {context.room}</h4>
            <section>
                <span>Users: <kbd> { users.length } </kbd></span>
            </section>

            <section>
                <span>Current state: {state}</span>
                <hr />
                <div role="group">
                    <button type="button" onClick={Play}>Play</button>
                    <button type="button" onClick={Pause}>Pause</button>
                    <button type="button" onClick={Sync}>Synchronize</button>
                </div>
            </section>

            <section>
                <input placeholder="Video URL" type="url" name="youtube-url" id="youtube-url" onChange={(e) => setURL(e.target.value)} />
                <button type="submit" onClick={WatchTogether}>Watch</button>
            </section>

            <section>
                <div>
                    {video ? (
                        <ReactPlayer url={video} controls={true} onPlay={Play} onPause={Pause} ref={playerRef} />
                    ) : (
                        <div></div>
                    )}
                </div>
            </section>

        </div>
    )
}
import React from "react";
import Room from "./components/Room";
import RoomLobby from "./components/Lobby";
import { createRoot } from "react-dom/client";
import { ToastContainer } from 'react-toastify';

// import "./styles.css"
import "./pico.min.css"

export const UserContext = React.createContext({
  room: "default",
  joined: false,
  users: []
});

function App() {
  return (
    <UserContext.Provider value={{ room: "default", joined: false, users: [] }}>
      <body>
        <main className="container">
          <h1>Youtube Teleparty 🎈🎉</h1>
          <RoomLobby />
          <ToastContainer />
        </main>
      </body>
    </UserContext.Provider>
  );
}

createRoot(document.getElementById("app")!).render(<App />);

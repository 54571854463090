import { useContext, useState } from "react"
import Room from "./Room";
import { UserContext } from "../client";

function RenderLobby() {
    const context = useContext(UserContext);
    const [roomName, setRoomName] = useState('default');
    const [joined, setJoined] = useState(false);

    const joinRoom = () => {
        context.room = roomName;
        context.joined = true;
        setJoined(true);
    }

    if (context.joined) {
        return (
            <Room />
        )
    }

    return (
        <div>
            <div>
                {/* <h4>Join room</h4> */}
                <input type="join-room-name" placeholder="Room name" onChange={(e) => setRoomName(e.target.value)} />
                <button type="submit" onClick={joinRoom}>Join</button>
            </div>
        </div>
    )
}

export default function Lobby() {
    return (
        <div>
            <RenderLobby />
        </div>
    )
}